import { getRequestConfig } from 'next-intl/server';

// Can be imported from a shared config
export const locales = ['de'] as const;
export const internalLocales = ['de'] as const;

export default getRequestConfig(async () => {
  // Validate that the incoming `locale` parameter is valid
  const locale = locales[0];
  return {
    locale,
    messages: (await import(`messages/${locale}.json`)).default,
  };
});
