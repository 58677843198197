'use client';

import { AppShell, Burger, Drawer, Group, Skeleton, UnstyledButton } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { Suspense, useEffect, useState } from 'react';
import classes from './Shell.module.css';

export type Props = {
  children: React.ReactNode;
  headerComponent?: React.ReactNode;
  footerComponent?: React.ReactNode | React.ReactNode[];
};

export function Shell({ headerComponent, children, footerComponent }: Props) {
  const [opened, { toggle }] = useDisclosure();
  const [openedAside, { toggle: toggleAside }] = useDisclosure();

  const [_undefined, setDesktop] = useState(false);

  const isDesktop = useMediaQuery('(min-width: 48em)', true, {
    getInitialValueInEffect: false,
  });

  useEffect(() => {
    isDesktop && isDesktop === true ? setDesktop(true) : setDesktop(false);
  }, [isDesktop]);

  return (
    <>
      <AppShell
        withBorder={false}
        header={{ height: 60 }}
        navbar={{ width: 300, breakpoint: 'sm', collapsed: { desktop: true, mobile: !opened } }}
        aside={{
          width: 100,
          breakpoint: 'sm',
          collapsed: { desktop: true, mobile: !openedAside },
        }}
        footer={{ offset: true, height: 100 }}
        padding="md"
        transitionTimingFunction="ease-in-out"
        offsetScrollbars={false}
      >
        {/* <Skeleton height="100%" width="80%"> */}
        <AppShell.Header withBorder={false}>
          <Suspense fallback={<Skeleton height={60} />}>
            {headerComponent ?? (
              <Group h="100%" px="md">
                <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
                <Group justify="space-between" style={{ flex: 1 }}>
                  LOGO
                  <Group ml="xl" gap={0} visibleFrom="sm">
                    <UnstyledButton className={classes.control}>Home</UnstyledButton>
                    <UnstyledButton className={classes.control}>Blog</UnstyledButton>
                    <UnstyledButton className={classes.control}>Contacts</UnstyledButton>
                    <UnstyledButton className={classes.control}>Support</UnstyledButton>
                  </Group>
                </Group>
              </Group>
            )}
          </Suspense>
        </AppShell.Header>

        <AppShell.Navbar py="md" px={4}>
          <UnstyledButton className={classes.control}>Home</UnstyledButton>
          <UnstyledButton className={classes.control}>Blog</UnstyledButton>
          <UnstyledButton className={classes.control}>Contacts</UnstyledButton>
          <UnstyledButton className={classes.control}>Support</UnstyledButton>
        </AppShell.Navbar>

        <AppShell.Main>{children}</AppShell.Main>

        <AppShell.Footer withBorder={false}>{footerComponent}</AppShell.Footer>
      </AppShell>

      <Drawer
        opened={openedAside}
        onClose={toggleAside}
        position="right"
        transitionProps={{ duration: 300 }}
        // classNames={{ content: classes.drawer }}
        trapFocus
        size="100%"
        // title="Account"
      >
        TEST1234
      </Drawer>
    </>
  );
}
