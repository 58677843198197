import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
// import userReducer from './slices/userSlice';
// import dragDropReducer from './slices/dragDropSlice';
import contactReducer from './slices/contactSlice';
import { backendAPI } from './slices/backendApi';

export const store = configureStore({
  reducer: {
    [backendAPI.reducerPath]: backendAPI.reducer,
    contact: contactReducer,
  },
  middleware: (getDefaultModdleware) => getDefaultModdleware().concat(backendAPI.middleware),
});
setupListeners(store.dispatch);

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;
