import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getCookie, hasCookie } from 'cookies-next';

export const backendAPI = createApi({
  reducerPath: 'backendApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.NEXT_PUBLIC_API}`,
    prepareHeaders: (headers) => {
      // use header as a fallback if cookie is not correctly sent to the server
      if (hasCookie(process.env.NEXT_PUBLIC_COOKIENAME as string)) {
        headers.set(
          process.env.NEXT_PUBLIC_COOKIENAME as string,
          getCookie(process.env.NEXT_PUBLIC_COOKIENAME as string) as string
        );
      }
      headers.set('Accept', 'application/json; charset=utf-8');
      return headers;
    },
    credentials: 'include',
    mode: 'cors',
  }),
  endpoints: (build) => ({
    root: build.query<string, void>({
      query: () => ({
        url: '/',
        method: 'GET',
        credentials: 'include',
      }),
    }),
  }),
});

export const { useRootQuery } = backendAPI;
