'use client';
import { CSSVariablesResolver, MantineColorsTuple, createTheme } from '@mantine/core';

const brightGreen: MantineColorsTuple = [
  '#effee7',
  '#e0f8d4',
  '#c2efab',
  '#a2e67e',
  '#87de57',
  '#75d940',
  '#6bd731',
  '#59be23',
  '#4da91b',
  '#3d920c',
];

const deepBlue: MantineColorsTuple = [
  '#ebefff',
  '#d5dafc',
  '#a9b1f1',
  '#7b87e9',
  '#5362e1',
  '#3a4bdd',
  '#2d3fdc',
  '#1f32c4',
  '#182cb0',
  '#0b259c',
];

export const theme = createTheme({
  primaryColor: 'red',
  primaryShade: 8,
  focusRing: 'auto',
  colors: {
    brightGreen,
    deepBlue,
  },
  cursorType: 'pointer',
  autoContrast: true,
  defaultGradient: {
    from: 'white',
    to: 'deeplBlue.9',
    deg: 45,
  },
});

export const resolver: CSSVariablesResolver = (theme) => ({
  variables: {
    '--mantine-api-url': 'url(' + process.env.NEXT_PUBLIC_API! + '/burgbrohl.webp)',
  },
  light: {},
  dark: {},
});
