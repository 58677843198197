import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { AppState } from '../store';
import { CreateSubmitDto } from './helmutmuellerAPI';

const initialState: CreateSubmitDto = {
  email: '',
  prename: '',
  surname: '',
  message: '',
};

//create a Slice for the contact page
const contactSlice = createSlice({
  name: 'contactFormState',
  initialState,
  reducers: {
    //set the state of the contact page
    setContact: (state, action: PayloadAction<Partial<CreateSubmitDto>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

//export the actions
export const { setContact } = contactSlice.actions;

//export the reducer
export default contactSlice.reducer;

//export the state
export const selectContact = (state: AppState) => state.contact;
